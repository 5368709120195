<script setup>
import MainArticleTitle from "@/huirong/components/MainArticleTitle.vue";
import { useI18n } from "vue-i18n";
// =========切换语言
const { t} = useI18n();
console.log(t)

</script>

<template>
  <section id="company-profile">
    <MainArticleTitle :h2-title='t("aboutUs.companyProfile[0]")' :h3-title='t("aboutUs.companyProfile[1]")'>
      <p style="margin-top: 50px">
        {{$t("aboutUs.companyProfile[2]")}}
      </p>
      <br/>
      <p>
        {{$t("aboutUs.companyProfile[3]")}}
      </p>
    </MainArticleTitle>
    <div class="gallery">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </section>
  <section id="service-scope">
    <MainArticleTitle :h2-title='t("aboutUs.serviceScope[0]")' :h3-title='t("aboutUs.serviceScope[1]")'></MainArticleTitle>
    <div class="service-scope-gallery">
      <figure>
        <img src="./assets/images/bg39.png" alt="Trulli">
        <figcaption>
          <p>{{$t("aboutUs.serviceScope[2]")}}</p>
          <p>{{$t("aboutUs.serviceScope[3]")}}</p>
        </figcaption>
      </figure>
      <figure>
        <img src="./assets/images/bg40.png" alt="Trulli">
        <figcaption>
          <p>{{$t("aboutUs.serviceScope[4]")}}</p>
          <p>{{$t("aboutUs.serviceScope[5]")}}</p>
        </figcaption>
      </figure>
      <figure>
        <img src="./assets/images/bg41.png" alt="Trulli">
        <figcaption>
          <p>{{$t("aboutUs.serviceScope[6]")}}</p>
          <p>{{$t("aboutUs.serviceScope[7]")}}</p>
        </figcaption>
      </figure>
      <figure>
        <img src="./assets/images/bg42.png" alt="Trulli">
        <figcaption>
          <p>{{$t("aboutUs.serviceScope[8]")}}</p>
          <p>{{$t("aboutUs.serviceScope[9]")}}</p>
        </figcaption>
      </figure>
      <figure>
        <img src="./assets/images/bg43.png" alt="Trulli">
        <figcaption>
          <p>{{$t("aboutUs.serviceScope[10]")}}</p>
          <p>{{$t("aboutUs.serviceScope[11]")}}</p>
        </figcaption>
      </figure>
      <figure>
        <img src="./assets/images/bg44.png" alt="Trulli">
        <figcaption>
          <p>{{$t("aboutUs.serviceScope[12]")}}</p>
          <p>{{$t("aboutUs.serviceScope[13]")}}</p>
        </figcaption>
      </figure>
      <figure>
        <img src="./assets/images/bg45.png" alt="Trulli">
        <figcaption>
          <p>{{$t("aboutUs.serviceScope[14]")}}</p>
          <p>{{$t("aboutUs.serviceScope[15]")}}</p>
        </figcaption>
      </figure>
      <figure>
        <img src="./assets/images/bg46.png" alt="Trulli">
        <figcaption>
          <p>{{$t("aboutUs.serviceScope[16]")}}</p>
          <p>{{$t("aboutUs.serviceScope[17]")}}</p>
        </figcaption>
      </figure>
      <figure>
        <img src="./assets/images/bg47.png" alt="Trulli">
        <figcaption>
          <p>{{$t("aboutUs.serviceScope[18]")}}</p>
          <p>{{$t("aboutUs.serviceScope[19]")}}</p>
        </figcaption>
      </figure>
      <figure>
        <img src="./assets/images/bg48.png" alt="Trulli">
        <figcaption>
          <p>{{$t("aboutUs.serviceScope[20]")}}</p>
          <p>{{$t("aboutUs.serviceScope[21]")}}</p>
        </figcaption>
      </figure>
    </div>
  </section>
  <section id="corporate-structure">
    <MainArticleTitle :h2-title='t("aboutUs.structure[0]")' :h3-title='t("aboutUs.structure[1]")'></MainArticleTitle>
    <div class="corporate-structure-image">
      <img src="./assets/images/bg49.png">
    </div>
  </section>
  <section id="brand-culture">
    <MainArticleTitle :h2-title='t("aboutUs.brandCulture[0]")' :h3-title='t("aboutUs.brandCulture[1]")'></MainArticleTitle>
    <div class="brand-culture">
      <div>
        <img src="./assets/images/bg50.png" alt="Trulli">
        <p>{{$t("aboutUs.brandCulture[2]")}}</p>
        <p>{{$t("aboutUs.brandCulture[3]")}}</p>
        <p>{{$t("aboutUs.brandCulture[4]")}}</p>
      </div>
      <div>
        <img src="./assets/images/bg51.png" alt="Trulli">
        <p>{{$t("aboutUs.brandCulture[5]")}}</p>
        <p>{{$t("aboutUs.brandCulture[6]")}}</p>
        <p>{{$t("aboutUs.brandCulture[7]")}}</p>
      </div>
      <div>
        <img src="./assets/images/bg52.png" alt="Trulli">
        <p>{{$t("aboutUs.brandCulture[8]")}}</p>
        <p>{{$t("aboutUs.brandCulture[9]")}}</p>
        <p>
          {{$t("aboutUs.brandCulture[10]")}}
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped>
@font-face {
  font-family: "SourceHanSansRegular";
  src: url('./assets/font/SourceHanSansCN-Regular.otf');
}
#company-profile{
  margin-top: 80px;
}
article p{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: small;
  font-family: SourceHanSansRegular, sans-serif;

}
.gallery{
  display: grid;
  /*align-items: stretch;*/
  width: 70%;
  margin: 30px auto 0;
}
.gallery>div{
  background-size: cover; /* 拉伸适应父元素 */
  background-position: center; /* 图片居中 */
  padding: 70px;
}
.gallery div:first-child{
  background-image: url("./assets/images/bg4.png");
  grid-column: 1 / span 2;
  grid-row: 1;
}
.gallery div:nth-child(2){
  background-image: url("./assets/images/bj1.png");
  grid-column: 3;
  grid-row: 1 / span 2;
}
.gallery div:nth-child(3){
  background-image: url("./assets/images/bg2.png");
}
.gallery div:nth-child(4){
  background-image: url("./assets/images/bg3.png");
}
#service-scope{
  background-color: rgb(225,230,231);
  margin-top: 75px;
  padding-top: 50px;
}
.service-scope-gallery{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}
.service-scope-gallery>figure{
  width: 16%;
  text-align: center;
  margin: auto 10px;
}
.service-scope-gallery>figure>figcaption>p:first-child{
  color: black;
}
.service-scope-gallery>figure>figcaption>p:nth-child(2){
  color: gray;
  font-size: small;
}
#corporate-structure{
  margin-top: 80px;
}
.corporate-structure-image{
  width: 100%;
  margin-top: 40px;
}
.corporate-structure-image>img{
  display: block;
  margin: 0 auto;
}
#brand-culture{
  background-color: rgb(234,239,241);
  padding-bottom: 80px;
}
.brand-culture{
  display: flex;
  justify-content: center;
  padding-top: 60px;
  margin-top: 70px;
}
.brand-culture>div{
  width: 20%;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
}
.brand-culture>div>img+p{
  font-size: x-large;
  margin-bottom: 0;
  background-image: linear-gradient(to right, #2158A0, #01D2FC); /* 背景线性渐变*/
  color: #ffffff;  /* 兜底颜色，防止文字裁剪不生效*/
  background-clip: text;
  -webkit-background-clip: text; /* 背景被裁减为文字的前景色*/
  -webkit-text-fill-color: transparent; /* 文字填充为透明，优先级比color高。*/

}
.brand-culture>div>img+p+p{
  font-size: small;
  color: rgb(128,128,128);
}
.brand-culture>div>img+p+p+p{
  font-size: small;
  margin-top: 30px;
  font-family: "SourceHanSansRegular", serif;
}
</style>
