import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus' //全局引入，elementUI

import i18n from './huirong/lang/index';// 国际化组件,多语言
import 'element-plus/dist/index.css'


createApp(App).use(ElementPlus)
    .use(i18n)
    .mount('#app')
