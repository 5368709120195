import { createI18n } from 'vue-i18n'   //引入vue-i18n组件
import zh from "./zh"
import en from "./en"

console.log(zh, '----')
const messages = {
    'zh': zh,// 中文语言包
    'en': en,// 英文语言包
}
console.log(23333, messages, zh)
//注册i8n实例并引入语言文件
const localeData = {
    legacy: false, // composition API
    locale: localStorage.getItem('language') || 'zh',
    messages,
}

export default createI18n(localeData);
