<script setup>
import HomePageBanner from './HomePageBanner.vue'
import AboutUsBanner from "@/huirong/header/AboutUsBanner.vue";
import CoreBusinessBanner from "@/huirong/header/CoreBusinessBanner.vue";
import CoreCompetencesBanner from "@/huirong/header/CoreCompetencesBanner.vue";
import BusinessScopeBanner from "@/huirong/header/BusinessScopeBanner.vue";
import ContactUsBanner from "@/huirong/header/ContactUsBanner.vue";
import {computed,onMounted, ref} from "vue";
import { useI18n } from "vue-i18n";



const routes = {
  '/': HomePageBanner,
  '/aboutUs': AboutUsBanner,
  '/coreBusiness': CoreBusinessBanner,
  '/coreCompetences': CoreCompetencesBanner,
  '/businessScope': BusinessScopeBanner,
  '/contactUs': ContactUsBanner
}
const activeClass = {
  '/': 1,
  '/aboutUs': 2,
  '/coreBusiness': 3,
  '/coreCompetences': 4,
  '/businessScope': 5,
  '/contactUs': 6
}

const currentPath = ref(window.location.hash)

window.addEventListener('hashchange', () => {
  currentPath.value = window.location.hash
})

const currentBanner = computed(() => {
  return routes[currentPath.value.slice(1) || '/'] || '没有';
})
const classHover = computed(() => {
  return activeClass[currentPath.value.slice(1) || '/'] || '没有';
})
//--------------------------------
function anchor(anchorName) {
  /*找到锚点*/
  let anchorElement = document.getElementById(anchorName);
  /*如果对应id的锚点存在，就跳转到锚点*/
  if(anchorElement) {
    anchorElement.scrollIntoView();
  }
}
onMounted(() => {
  console.log(`the component is now mounted.`)
})

// =========切换语言
const { t, locale } = useI18n();
console.log(t('menus[0]["name"]'), '===')

function handleLang () {
  let val = localStorage.getItem('language') || 'zh'
  val = val === "zh"?"en":"zh";
  //console.log(23333, val, locale)
  locale.value = val;
  localStorage.setItem('language', val)
}

</script>

<template>
  <header>
    <div class="navigation-container">
    <div class="navigation">
      <span style="display:inline-block;padding-right: 60px;padding-left: 20px">
      <img src="../assets/images/logo1.png" class="logo" alt="logo">
      </span>
      <nav>
        <ul>
          <li onmouseover="this.firstChild.classList.add('menu-selected');" onmouseleave="this.firstChild.classList.remove('menu-selected');">
            <a href="#/" :class="classHover === 1 ? 'menu-selected-hover':''">{{$t("menus[0]['name']")}}</a></li>
          <li class="menu-level1"
              onmouseover="this.firstChild.classList.add('menu-selected');this.children[1].classList.add('sub-menu-selected');"
              onmouseleave="this.firstChild.classList.remove('menu-selected');this.children[1].classList.remove('sub-menu-selected');"
          >
            <a href="#/aboutUs" :class="classHover === 2 ? 'menu-selected-hover':''">{{$t("menus[1]['name']")}}</a>
            <ul class="">
              <li><a href="#/aboutUs" @click="anchor('company-profile')">{{$t("menus[1]['subMenus'][0]")}}</a></li>
              <li><a href="#/aboutUs" @click="anchor('service-scope')">{{$t("menus[1]['subMenus'][1]")}}</a></li>
              <li><a href="#/aboutUs" @click="anchor('corporate-structure')">{{$t("menus[1]['subMenus'][2]")}}</a></li>
              <li><a href="#/aboutUs" @click="anchor('brand-culture')">{{$t("menus[1]['subMenus'][3]")}}</a></li>
            </ul>
          </li>
          <li class="menu-level1"
              onmouseover="this.firstChild.classList.add('menu-selected');this.children[1].classList.add('sub-menu-selected');"
              onmouseleave="this.firstChild.classList.remove('menu-selected');this.children[1].classList.remove('sub-menu-selected');"
          >
            <a href="#/coreBusiness" :class="classHover === 3 ? 'menu-selected-hover':''">{{$t("menus[2]['name']")}}</a>
            <ul class="sub-menu">
              <li><a href="#/coreBusiness">{{$t("menus[2]['subMenus'][0]")}}</a></li>
              <li><a href="#/coreBusiness">{{$t("menus[2]['subMenus'][1]")}}</a></li>
              <li><a href="#/coreBusiness">{{$t("menus[2]['subMenus'][2]")}}</a></li>
            </ul>
          </li>
          <li class="menu-level1"
              onmouseover="this.firstChild.classList.add('menu-selected');this.children[1].classList.add('sub-menu-selected');"
              onmouseleave="this.firstChild.classList.remove('menu-selected');this.children[1].classList.remove('sub-menu-selected');"
          >
            <a href="#/coreCompetences" :class="classHover === 4 ? 'menu-selected-hover':''">{{$t("menus[3]['name']")}}</a>
            <ul class="sub-menu sub-menu3">
              <li><a href="#/coreCompetences">{{$t("menus[3]['subMenus'][0]")}}</a></li>
              <li><a href="#/coreCompetences">{{$t("menus[3]['subMenus'][1]")}}</a></li>
              <li><a href="#/coreCompetences">{{$t("menus[3]['subMenus'][2]")}}</a></li>
              <li><a href="#/coreCompetences">{{$t("menus[3]['subMenus'][3]")}}</a></li>
              <li><a href="#/coreCompetences">{{$t("menus[3]['subMenus'][4]")}}</a></li>
              <li><a href="#/coreCompetences">{{$t("menus[3]['subMenus'][5]")}}</a></li>
            </ul>
          </li>

          <li class="menu-level1"
              onmouseover="this.firstChild.classList.add('menu-selected');this.children[1].classList.add('sub-menu-selected');"
              onmouseleave="this.firstChild.classList.remove('menu-selected');this.children[1].classList.remove('sub-menu-selected');"
          >
            <a href="#/businessScope" :class="classHover === 5 ? 'menu-selected-hover':''">{{$t("menus[4]['name']")}}</a>
            <ul class="sub-menu sub-menu4">
              <li><a href="#/businessScope">{{$t("menus[4]['subMenus'][0]")}}</a></li>
              <li><a href="#/businessScope">{{$t("menus[4]['subMenus'][1]")}}</a></li>
              <li><a href="#/businessScope">{{$t("menus[4]['subMenus'][2]")}}</a></li>
              <li><a href="#/businessScope">{{$t("menus[4]['subMenus'][3]")}}</a></li>
              <li><a href="#/businessScope">{{$t("menus[4]['subMenus'][4]")}}</a></li>
              <li><a href="#/businessScope">{{$t("menus[4]['subMenus'][5]")}}</a></li>
            </ul>
          </li>
          <li class="menu-level1"
              onmouseover="this.firstChild.classList.add('menu-selected');this.children[1].classList.add('sub-menu-selected');"
              onmouseleave="this.firstChild.classList.remove('menu-selected');this.children[1].classList.remove('sub-menu-selected');"
          >
            <a href="#/contactUs" :class="classHover === 6 ? 'menu-selected-hover':''">{{$t("menus[5]['name']")}}</a>
            <ul class="sub-menu">
              <li><a href="#/contactUs">{{$t("menus[5]['subMenus'][0]")}}</a></li>
              <li><a href="#/contactUs">{{$t("menus[5]['subMenus'][1]")}}</a></li>
            </ul>
          </li>
        </ul>
      </nav>
      <!--------切换网站语言------>
      <div class="languages-switcher">
        <button @click="handleLang()"></button>
      </div>
    </div>
    </div>
    <div class="banner">
      <component :is="currentBanner"/>
    </div>
  </header>
</template>

<style scoped>
.navigation-container {
  position: fixed;
  z-index: 1;
  width: 100%;
}

.navigation {
  width: 1441px;
  display: flex;
  justify-content: center;
  align-items: center; /* ★ */
  border-bottom: 2px solid rgba(240, 240, 240, 0.3);
  background-color: rgba(0,55,190,0.4);
  margin: 0 auto;
}

.menu-level1 {
  position: relative;
}

ul {
  list-style: none;

}

nav ul li a {
  color: #fff;
  text-decoration: none;

}

nav > ul > li > a {
  padding-bottom: 22px;
  font-size: small;
}

nav > ul > li > a.menu-selected {
  border-bottom: 3px solid rgba(240, 240, 240, 0.3);
}
nav > ul > li > a.menu-selected-hover {
  border-bottom: 3px solid rgba(240, 240, 240, 0.3);
}

.navigation nav {
  width: 660px;
  display: inline-block;
}

.navigation nav > ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin-top: 23px;
  margin-bottom: 23px;
  /*width: 580px;*/
}
.navigation nav > ul>li{
  padding: 0 10px;
}
.languages-switcher {
  display: inline-block;
  width: 17%;
  text-align: center;
}

.logo {
  padding-left: 10px;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url("../assets/images/switchla.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 22px;
  height: 22px;
}

.menu-level1 > ul {
  position: absolute;
  width: 100%;
  padding: 0;
  left: -4px;
  top: 45px;
  display: none;
}

.menu-level1 > ul > li {
  background-color: rgba(10, 49, 140, 0.7);
  text-align: center;
}

.menu-level1 > ul > li > a {
  font-size: small;
  color: #fff;
  border-bottom: 1px solid;
  padding: 5px 10px;
}

.menu-level1 > ul.sub-menu-selected {
  display: block;
}

.menu-level1 > ul.sub-menu3 {
  width: 410%;
  left: -156%;
}

.menu-level1 > ul.sub-menu4 {
  width: 188%;
  left: -33%;
}
</style>