const zh = {
    messages: {
        "upload": "上传"
    },
    menus:[
        {
            'name':'首页',
            subMenus:[]
        },
        {
            name:'关于我们',
            subMenus: [
                '企业简介',
                '服务领域',
                '组织框架',
                '品牌文化',
            ]
        },
        {
            name:'核心业务',
            subMenus: [
                '业务板块',
                '经典案例',
                '案例精选',
            ]
        },
        {
            name:'核心竞争力',
            subMenus: [
                '高素质的专业团队',
                '量体裁衣式精准定制服务',
                '严谨的货运质量及风险管控',
                '可优先调配的运力资源',
                '丰富的承运人资源和完善的国内外物流合作伙伴',
                '极具竞争力的优势航线',
            ]
        },
        {
            name:'承运范围',
            subMenus: [
                '管道系统',
                '车辆类',
                '钢铁制品',
                '高端装备重大件',
                '危险品',
                '大宗干散货',
            ]
        },
        {
            name:'联系我们',
            subMenus: [
                '联系我们',
                '人才招聘'
            ]
        },

    ],
    homePage:{
        banner:[
            [
                '懂你的管道和工程物流专家',
                'PIPE & PROJECT LOGISTICS EXPERT YOUR BEST PARTNER'
            ],
            [
                '我们所过的每一个平凡的日常<br/>也就是连续发生的奇迹',
                'every ordinary but persistent working day we have<br/>witnessed miracle'
            ],
            [
                '用工匠精神做事、用契约精神做人',
                'Thriving by spirits of Artisan and Contract'
            ],
            [
                '为客户提供增值服务<br/>为员工提供事业发展平台是我们存在的价值',
                'To provide valued-added services to our customers and career<br/>development platform for our employees.'
            ],
            [
                '以诚为先谋取共同发展<br/>坚持伙伴式多赢的合作理念',
                'Strive for win-win solutions with our<br/> customers and partners'
            ]
        ],
        aboutUs:[
            'ABOUT US',
            '关于我们',
            '上海徽荣国际物流有限公司成立于2008年，专业从事管道、钢铁制品、公路及铁路客货运车辆、国际工程项目物资、高端装备和重大件、危险品（一类、五类及九类）、大宗干散货等进出口货物的租船、订舱、港口地面货代以及门到门一站式物流服务，在管道、车辆和工程项目物流行业享有盛誉。'
        ],
        coreBusiness:[
            'CORE BUSINESS',
            '核心业务',
            '件杂货国际海运租船业务',
            '门到门一站式物流服务',
            '大宗干散租船业务',
            '港口地面货运代理业务',
        ],
        coreCompetences:[
            'CORE COMPETENCES',
            '核心竞争力',
            '高素质的专业团队',
            'A professional team with expertise and experiences',
            '量体裁衣式精准定制服务',
            'The precise tailor-made and customized services',
            '严谨的货运质量及风险管控',
            'Prudent risk management system',
            '可优先调配的运力资源',
            'Abundant and prioritized vessels’ capacity resources',
            '丰富的承运人资源和完善的国内外物流合作伙伴',
            'Good access to carriers and impeccable domestic and international logistics partners',
            '极具竞争力的优势航线',
            'Highly competitive and advantageous routes',
        ],
        qualityRoute:[
            'QUALITY ROUTE',
            '优质航线'
        ],
        contactUs:[
            'CONTACT US',
            '联系我们',
            '上海市昆明路518号北美广场A座1010室',
            '+86 21 6325 8800',
            '+86 21 6325 2211',
            '邮箱',
            '网站｜www.gblogistics.cn',
        ]
    },
    footer:[
        '联系热线',
        '微信公众号',
        '关于我们',
        '企业简介',
        '服务领域',
        '组织架构',
        '品牌文化',
        '核心业务',
        '业务板块',
        '经典案例',
        '案例精选',
        '核心竞争力',
        '高素质的专业团队',
        '量体裁衣式精准定制服务',
        '严谨的货运质量及风险管控',
        '可优先调配的运力资源',
        '丰富的承运人资源和完善的国内外物流合作伙伴',
        '极具竞争力的优势航线',
        '承运范围',
        '管道系统',
        '车辆类',
        '钢铁制品',
        '高端装备重大件',
        '危险品',
        '大宗干散货',
        '联系我们',
        '联系我们',
        '人才招聘',
    ],
    aboutUs: {
        banner:[
            'ABOUT US',
            '关于我们'
        ],
        companyProfile:[
            'COMPANY PROFILE',
            '企业简介',
            '上海徽荣国际物流有限公司成立于2008年，专业从事管道、钢铁制品、公路及铁路客货运车辆、国际工程项目物资、高端装备和重大件、危险品（一类、五类及九类）、大宗干散货等进出口货物的租船、订舱、港口地面货代以及门到门一站式物流服务。在管道、车辆和工程项目物流行业享有盛誉',
            '徽荣物流始终秉承“以诚为先，谋求共同发展；以人为本，追求完美服务”的经营理念，依托丰富的一流的承运人合作伙伴，协同国内外一流的头部物流供应商，立足一带一路、RCEP十五国多边协定和金砖十一国间的贸易和投资活动，致力于为每一个客户的每一次托付，提供专业化、个性化的管家式精准定制服务。'
        ],
        serviceScope:[
            'SERVICE SCOPE',
            '服务领域',
            '自来水供应',
            'Water supply',
            '港口码头建设和航道疏浚',
            'Terminal building and channel dredging',
            '油气开采和输送',
            'Oil and gas exploitation and transportation',
            '污水处理',
            'Sewage disposal',
            '冶金',
            'Metallurgy',
            '汽车',
            'Automobile',
            '化工',
            'Chemical industry',
            '矿产资源',
            'Mining',
            '电力及新能源',
            'Electricity and new Energy',
            '公铁路',
            'Highways and railways',
        ],
        structure:[
            'CORPORATE STRUCTURE',
            '组织架构'
        ],
        brandCulture:[
            'BRAND CULTURE',
            '品牌文化',
            '我们的使命',
            'OUR MISSION',
            '通过个性化制定完善的物流解决方案，为客户提供更加安全、优质、节省、快捷的全球物流服务，以提高客户产品的市场竞争力，提升客户品牌的全球声誉。',
            '我们的愿景',
            'OUR VISION',
            '致力于管道、车辆和工程项目全球一站式综合物流服务，努力把徽荣国际物流建设成为可传承可持续发展的中小型物流企业的典范。',
            '我们的价值观',
            'OUR VALUE',
            '√精准匹配的服务才是好的服务；\n √为客户提供增值服务、为员工提供事业发展平台 √是我们存在的价值；√持续改进、不断创新、追求卓越'
        ]
    }
}
export default zh