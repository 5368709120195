<script setup>
import { useI18n } from "vue-i18n";
// =========切换语言
const { t} = useI18n();
console.log(t)
</script>

<template>
  <footer>
    <section class="web-footer-top">
    <section class="web-footer-top-left">
      <div><img src="./assets/images/bg24.png" alt="LOGO"></div>
      <address>
        <div>
          <p>{{$t("footer[0]")}}</p>
          <p>86 21 6325 8800</p>
        </div>
        <figure>
          <img src="./assets/images/bg26.png" alt="Trulli">
          <figcaption>{{$t("footer[1]")}}</figcaption>
        </figure>
      </address>
    </section>
    <section class="web-footer-top-right">
      <ul>
        <li><a href="#/aboutUs">{{$t("footer[2]")}}</a></li>
        <li><a href="#/aboutUs">{{$t("footer[3]")}}</a></li>
        <li><a href="#/aboutUs">{{$t("footer[4]")}}</a></li>
        <li><a href="#/aboutUs">{{$t("footer[5]")}}</a></li>
        <li><a href="#/aboutUs">{{$t("footer[6]")}}</a></li>
      </ul>
      <ul>
        <li><a href="#/coreBusiness">{{$t("footer[7]")}}</a></li>
        <li><a href="#/coreBusiness">{{$t("footer[8]")}}</a></li>
        <li><a href="#/coreBusiness">{{$t("footer[9]")}}</a></li>
        <li><a href="#/coreBusiness">{{$t("footer[10]")}}</a></li>
      </ul>
      <ul>
        <li><a href="#/coreCompetences">{{$t("footer[11]")}}</a></li>
        <li><a href="#/coreCompetences">{{$t("footer[12]")}}</a></li>
        <li><a href="#/coreCompetences">{{$t("footer[13]")}}</a></li>
        <li><a href="#/coreCompetences">{{$t("footer[14]")}}</a></li>
        <li><a href="#/coreCompetences">{{$t("footer[15]")}}</a></li>
        <li><a href="#/coreCompetences">{{$t("footer[16]")}}</a></li>
        <li><a href="#/coreCompetences">{{$t("footer[17]")}}</a></li>
      </ul>
      <ul>
        <li><a href="#/businessScope">{{$t("footer[18]")}}</a></li>
        <li><a href="#/businessScope">{{$t("footer[19]")}}</a></li>
        <li><a href="#/businessScope">{{$t("footer[20]")}}</a></li>
        <li><a href="#/businessScope">{{$t("footer[21]")}}</a></li>
        <li><a href="#/businessScope">{{$t("footer[22]")}}</a></li>
        <li><a href="#/businessScope">{{$t("footer[23]")}}</a></li>
        <li><a href="#/businessScope">{{$t("footer[24]")}}</a></li>
      </ul>
      <ul>
        <li><a href="#/contactUs">{{$t("footer[25]")}}</a></li>
        <li><a href="#/contactUs">{{$t("footer[26]")}}</a></li>
        <li><a href="#/contactUs">{{$t("footer[27]")}}</a></li>
      </ul>
    </section>
    </section>
    <section class="web-footer-bottom">
      <p>Copyright © 2016 - 2017 huirongwuliu. All Rights Reserved</p>
      <p>© 2024 徽荣物流有限公司 版权所有</p>
    </section>
  </footer>
</template>

<style scoped>
footer{
  border-top: 10px solid rgb(7,184,233);
  padding-top: 65px;
}
.web-footer-top{
  display: flex;
  margin: 0 auto;
  width: 1441px;
  justify-content: center;
}
address{
  padding-top: 45px;
  padding-left: 15px;
  display: flex;
}
address>div>p:first-child{
  padding-left: 20px;
  background: url(./assets/images/bg25.png) left center no-repeat;
  font-weight:800;
}
address>div>p:nth-child(2){
  font-size: medium;
  font-weight:800;
}
address>figure{
  padding-left: 80px;
  text-align: center;
  font-size: 10px;
  padding-top: 20px;
}
.web-footer-top-left{
  height: 100%;
  border-right: 1px solid rgb(100,100,100);
  padding-left: 50px;
}
.web-footer-top-right{
  display: flex;
}
.web-footer-top-right>ul{
  list-style: none;
  max-width: 22%;
}
.web-footer-top-right>ul>li{
  padding-bottom: 10px;
}
.web-footer-top-right>ul>li>a{
  font-size: small;
  color: rgb(140,140,140);
  text-decoration:none;
}
.web-footer-top-right>ul>li:first-child{
  font-weight:800;
}
.web-footer-top-right>ul>li:first-child>a{
  color: black;
}
.web-footer-bottom{
  padding-top: 20px;
  padding-bottom: 15px;
}
.web-footer-bottom>p{
  margin: 0 auto;
  width: 80%;
  text-align: center;
  font-size: 8px;
  color: rgb(140,140,140);
}
address>div{
  width: 210px;
}
address{
  font-style:normal;
}
</style>