const en = {
    messages: {
        "upload": "上传"
    },
    menus:[
        {
            'name':'HOME',
            subMenus:[]
        },
        {
            name:'ABOUT US',
            subMenus: [
                'COMPANY PROFILE',
                'SERVICE SCOPE',
                'CORPORATE STRUCTURE',
                'BRAND CULTURE',
            ]
        },
        {
            name:'CORE BUSINESS',
            subMenus: [
                'CORE BUSINESS',
                'CLASSICAL CASE',
                'SELECTED CASES',
            ]
        },
        {
            name:'CORE COMPETENCES',
            subMenus: [
                'A PROFESSIONAL TEAM WITH EXPERTISE AND EXPERIENCES',
                'THE PRECISE TAILOR-MADE AND CUSTOMIZED SERVICES',
                'RIGOROUS FREIGHT QUALITY AND RISK CONTROL',
                'ABUNDANT AND PRIORITIZED VESSEL CAPACITY RESOURCES',
                'GOOD ACCESS TO CARRIERS AND WELL-ESTABLISHED DOMESTIC AND INTERNATIONAL LOGISTICS PARTNERS',
                'EXTREMELY COMPETITIVE TRADING LINES',
            ]
        },
        {
            name:'BUSINESS SCOPE',
            subMenus: [
                'PIPELINE',
                'VEHICLES',
                'STEEL PRODUCTS',
                'ENGINEERING PROJECT',
                'IMDG CARGO',
                'OPERAING BUSINESS',
            ]
        },
        {
            name:'CONTACT US',
            subMenus: [
                'CONTACT US',
                'RECRUIT'
            ]
        },

    ],
    homePage:{
        banner:[
            [
                'PIPE & PROJECT LOGISTICS EXPERT<br/> YOUR BEST PARTNER',
                ''
            ],
            [
                'every ordinary but persistent working day we have<br/>witnessed miracle',
                ''
            ],
            [
                'Thriving by spirits of Artisan and Contract',
                ''
            ],
            [
                'To provide valued-added services to our customers and career<br/>development platform for our employees.',
                'To provide valued-added services to our customers and career<br/>development platform for our employees.'
            ],
            [
                'Strive for win-win solutions with our<br/> customers and partners',
                ''
            ]
        ],
        aboutUs:[
            'ABOUT US',
            '',
            'Shanghai Golden Banner Int’l Logistics Co., Ltd. (“GBL”), founded in 2008, specializes in chartering, cargo booking & forwarding, and door to door logistic services for import & export of all kinds of pipelines, engineering project cargos, vehicles and trains, high value equipment, over-size and over-weight cargoes, steel products, IMDG(Class 1, Class 5 & Class 9 etc.), dry bulk commodities Operating business etc. GBL is a well-known name in the sector of engineering logistics especially for pipeline transport.'
        ],
        coreBusiness:[
            'CORE BUSINESS',
            '',
            'International Chartering and Cargo Forwarding of Breakbulk Cargoes',
            'Door to Door One-stop Logistic Service',
            'Operating Business for Dry Bulk Commodities',
            'Local Freight Forwarding Business',
        ],
        coreCompetences:[
            'CORE COMPETENCES',
            '',
            'A professional team with expertise and experiences',
            '',
            'The precise tailor-made and customized services',
            '',
            'Prudent risk management system',
            '',
            'Abundant and prioritized vessels’ capacity resources',
            '',
            'Good access to carriers and impeccable domestic and international logistics partners',
            '',
            'Highly competitive and advantageous routes',
            '',
        ],
        qualityRoute:[
            'QUALITY ROUTE',
            ''
        ],
        contactUs:[
            'CONTACT US',
            '',
            'RM1010,Block A,North America Plaza,No.518 Kunming Rd,Shanghai,China PC 200082',
            '+86 21 6325 8800',
            '+86 21 6325 2211',
            '',
            'www.gblogistics.cn',
        ]
    },
    footer:[
        '联系热线',
        'WeChat',
        'ABOUT US',
        'COMPANY PROFILE',
        'SERVICE SCOPE',
        'CORPORATE STRUCTURE',
        'BRAND CULTURE',
        'CORE BUSINESS',
        'CORE BUSINESS',
        'CLASSICAL CASE',
        'SELECTED CASES',
        'CORE COMPETENCES',
        'A PROFESSIONAL TEAM WITH EXPERTISE AND EXPERIENCES',
        'THE PRECISE TAILOR-MADE AND CUSTOMIZED SERVICES',
        'RIGOROUS FREIGHT QUALITY AND RISK CONTROL',
        'ABUNDANT AND PRIORITIZED VESSEL CAPACITY RESOURCES',
        'GOOD ACCESS TO CARRIERS AND WELL-ESTABLISHED DOMESTIC AND INTERNATIONAL LOGISTICS PARTNERS',
        'EXTREMELY COMPETITIVE TRADING LINES',
        'BUSINESS SCOPE',
        'PIPELINE',
        'VEHICLES',
        'STEEL PRODUCTS',
        'ENGINEERING PROJECT',
        'IMDG CARGO',
        'OPERAING BUSINESS',
        'CONTACT US',
        'CONTACT US',
        'RECRUIT',
    ],
    aboutUs: {
        banner:[
            'ABOUT US',
            ''
        ],
        companyProfile:[
            'COMPANY PROFILE',
            '',
            'Shanghai Golden Banner Int’l Logistics Co., Ltd. (“GBL”), founded in 2008, specializes in chartering, cargo booking & forwarding, and door to door logistic services for import & export of all kinds of pipelines, engineering project cargos, vehicles and trains, high value equipment, over-size and over-weight cargoes, steel products, IMDG(Class 1, Class 5 & Class 9 etc.), dry bulk commodities Operating business etc. GBL is a well-known name in the sector of engineering logistics especially for pipeline transport.',
            'GBL has always been adhering to the business philosophy-- "sincerity-driven, seeking for common development; Customer-oriented, the pursuit of perfect service", relying on a wealth of first-class carrier partners, in collaboration with domestic and international first-class leading logistics providers, based on the “Belt and Road”, the RCEP fifteen-country multilateral Agreement and the BRICS eleven-country trade and investment activities, is committed to each customer\'s every consignment, to provide professional, personalized butler-type precision customized services.'
        ],
        serviceScope:[
            'SERVICE SCOPE',
            '',
            'Water supply',
            '',
            'Terminal building and channel dredging',
            '',
            'Oil and gas exploitation and transportation',
            '',
            'Sewage disposal',
            '',
            'Metallurgy',
            '',
            'Automobile',
            '',
            'Chemical industry',
            '',
            'Mining',
            '',
            'Electricity and new Energy',
            '',
            'Highways and railways',
            '',
        ],
        structure:[
            'CORPORATE STRUCTURE',
            ''
        ],
        brandCulture:[
            'BRAND CULTURE',
            '',
            'OUR MISSION',
            '',
            'Through the development of the best logistics solutions, we provide our customers with safer, high-quality, cost-effective and faster global logistics services to enhance the competitiveness of their products in the market and the global reputation of their brands',
            'OUR VISION',
            '',
            'Dedicated to the global one-stop integrated logistics service for pipelines and engineering projects, we endeavor to build GBL into a model of small and medium-sized logistics enterprise with inheritable and sustainable development.',
            'OUR VALUE',
            '',
            '√The most suitable is the best；<br/>√To provide value-added services to our customers and career development platform for our employees<br/>√Continuous improvement, Sustained innovation, Striving for excellence.'
        ]
    }
}
export default en