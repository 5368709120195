<script setup>
import { useI18n } from "vue-i18n";
// =========切换语言
const { t} = useI18n();
console.log(t)
const bannerContent = [
  '<p style="padding-top: 345px;padding-left: 226px;font-size: xx-large;width: 1441px;margin: 0 auto;">'+t("homePage.banner[0][0]")+'</p>' +
  '<p style="padding-left: 226px;font-size: x-large;width: 1441px;margin: 5px auto auto auto;">' +
  t("homePage.banner[0][1]") +
  '</p>',

  '<p style="padding-top: 345px;padding-left: 226px;font-size: xx-large;width: 1441px;margin: 0 auto;">'+t("homePage.banner[1][0]")+'</p>' +
  '<p style="padding-left: 226px;font-size: x-large;width: 1441px;margin: 5px auto auto auto;">' +
  t("homePage.banner[1][1]") +
  '</p>',

  '<p class="banner-img-word" style="padding-top: 345px;padding-left: 226px;font-size: xx-large;width: 1441px;margin: 0 auto;">'+t("homePage.banner[2][0]")+'</p>' +
  '<p style="padding-left: 226px;font-size: x-large;width: 1441px;margin: 5px auto auto auto;">' +
  t("homePage.banner[2][1]") +
  '</p>',

  '<p class="banner-img-word" style="padding-top: 345px;padding-left: 226px;font-size: xx-large;width: 1441px;margin: 0 auto;">'+t("homePage.banner[3][0]")+'</p>' +
  '<p style="padding-left: 226px;font-size: x-large;width: 1441px;margin: 5px auto auto auto;">' +
  t("homePage.banner[3][1]") +
  '</p>',

  '<p class="banner-img-word" style="padding-top: 345px;padding-left: 226px;font-size: xx-large;width: 1441px;margin: 0 auto;">'+t("homePage.banner[4][0]")+'</p>' +
  '<p style="padding-left: 15%;font-size: x-large;width: 1441px;margin: 5px auto auto auto;">' +
  t("homePage.banner[4][1]") +
  '</p>',
]
</script>

<template>

    <div class="block text-center" m="t-4">
      <!--span class="demonstration">Switch when indicator is clicked</span-->
      <el-carousel autoplay height="701px" trigger="click" interval="">
        <el-carousel-item v-for="item in bannerContent" :key="item">
          <div class="justify-center" text="{{item}}" v-html="item">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

</template>

<style scoped>
.demonstration {
  color: var(--el-text-color-secondary);
}

.el-carousel__item div {
  color: #ffffff;
  /*opacity: 0.75;*/
  /*line-height: 600px;*/
  margin: 0;
  /*text-align: center;*/
}
.justify-center{
  width: 100%;
}


.el-carousel__item:nth-child(1) {
  background: url("../assets/images/carousel1.png") center top no-repeat
}

.el-carousel__item:nth-child(2) {
  background: url("../assets/images/carousel5.png") center top no-repeat
}

.el-carousel__item:nth-child(3) {
  background: url("../assets/images/carousel3.png") center top no-repeat
}

.el-carousel__item:nth-child(4) {
  background: url("../assets/images/carousel4.png") center top no-repeat
}

.el-carousel__item:nth-child(5) {
  background: url("../assets/images/carousel5.png") center top no-repeat
}

</style>


