<script setup>

</script>

<template>
  <div class="about-us-banner">
    <div class="container">
      <p>BUSINESS SCOPE</p>
      <p>承运范围</p>
    </div>
  </div>
</template>

<style scoped>
.about-us-banner {
  background: url("../assets/images/bg103.png") center top no-repeat;
  height: 444px;
  width: 1441px;
  margin: 0 auto;
}
.about-us-banner>.container{
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.about-us-banner>.container>p{
  padding-left: 280px;
  font-size: xx-large;
  color: white;
  margin: 0;
}
</style>