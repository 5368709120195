<script setup>
import HomePageMain from './HomePageMain.vue'
import AboutUsMain from './AboutUsMain.vue'
import CoreBusinessMain from './CoreBusinessMain.vue'
import CoreCompetencesMain from './CoreCompetencesMain.vue'
import BusinessScopeMain from './BusinessScopeMain.vue'
import ContactUsMain from './ContactUsMain.vue'


import {computed, ref} from "vue";
//const currentMain = AboutUsMain;

const routes = {
  '/': HomePageMain,
  '/aboutUs': AboutUsMain,
  '/coreBusiness': CoreBusinessMain,
  '/coreCompetences': CoreCompetencesMain,
  '/businessScope': BusinessScopeMain,
  '/contactUs': ContactUsMain
}

const currentPath = ref(window.location.hash)

window.addEventListener('hashchange', () => {
  currentPath.value = window.location.hash
})

const currentMain = computed(() => {
  return routes[currentPath.value.slice(1) || '/'] || '没有';
})


</script>

<template>


  <main>
    <component :is="currentMain" />
  </main>



</template>

<style>
/* || 一般设置 */

html, body {
  margin: 0;
  padding: 0;
}

/*html {
  font-size: 10px;
}*/

body {
  width: 100%;
  min-width: 1440px;
  margin: 0 auto;
}



</style>