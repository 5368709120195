<script setup>

</script>

<template>
  <div id="contact-us-main">
    <ul>
      <li>上海市昆明路518号北美广场A座1010室</li>
      <li>+86 21 6325 8800</li>
      <li>+86 21 6325 2211</li>
      <li>邮箱｜info@gblogistics.cn</li>
      <li>网站｜www.gblogistics.cn</li>
    </ul>
    <img src="./assets/images/bg182.png" alt="map">
  </div>

</template>

<style scoped>
#contact-us-main{
  display: flex;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 80px;
}
#contact-us-main ul{
  background-color: rgb(195,199,205);
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 40px;
}
#contact-us-main ul li{
  font-size: small;
  list-style: none;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
#contact-us-main ul li:first-child{
  background:url("./assets/images/bg19.png") left center no-repeat;
}
#contact-us-main ul li:nth-child(2){
  background:url("./assets/images/bg20.png") left center no-repeat;
}
#contact-us-main ul li:nth-child(3){
  background:url("./assets/images/bg21.png") left center no-repeat;
}
#contact-us-main ul li:nth-child(4){
  background:url("./assets/images/bg22.png") left center no-repeat;
}
#contact-us-main ul li:nth-child(5){
  background:url("./assets/images/bg23.png") left center no-repeat;
}

#contact-us-main img{

}
</style>