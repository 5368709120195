<script setup>
import MainArticleTitle from './components/MainArticleTitle.vue'
import { useI18n } from "vue-i18n";
// =========切换语言
const { t} = useI18n();
console.log(t)

</script>

<template>
  <section id="about-us">
    <MainArticleTitle :h2-title='t("homePage.aboutUs[0]")' :h3-title='t("homePage.aboutUs[1]")'>
      <p>{{$t("homePage.aboutUs[2]")}}</p>
    </MainArticleTitle>
    <div class="gallery">
      <div></div>
      <div></div>
      <div class="gallery-HPM3"></div>
      <div class="gallery-HPM4"></div>
    </div>
    <div class="about-us-learn-more">
      <button class="about-us-learn-more-button"><a>LEARN MORE</a></button>
    </div>
  </section>
  <section id="core-business">
    <article>
      <h2 class="biz-h2">{{$t("homePage.coreBusiness[0]")}}</h2>
      <h3 class="biz-h3">{{$t("homePage.coreBusiness[1]")}}</h3>
      <div class="line"></div>
    </article>
    <div class="core-business-gallery">
      <figure>
        <img src="./assets/images/bg6.png" alt="Trulli" style="width:230px;height: 152px">
        <figcaption>{{$t("homePage.coreBusiness[2]")}}</figcaption>
      </figure>
      <figure>
        <img src="./assets/images/bg7.png" alt="Trulli" style="width:230px;height: 152px">
        <figcaption>{{$t("homePage.coreBusiness[3]")}}</figcaption>
      </figure>
      <figure>
        <img src="./assets/images/bg8.png" alt="Trulli" style="width:230px;height: 152px">
        <figcaption>{{$t("homePage.coreBusiness[4]")}}</figcaption>
      </figure>
      <figure>
        <img src="./assets/images/bg9.png" alt="Trulli" style="width:230px;height: 152px">
        <figcaption>{{$t("homePage.coreBusiness[5]")}}</figcaption>
      </figure>
    </div>
    <div class="core-business-learn-more">
      <button class="core-business-learn-more-button"><a>LEARN MORE</a></button>
    </div>
  </section>
  <section id="core-competences">
    <MainArticleTitle :h2-title='t("homePage.coreCompetences[0]")' :h3-title='t("homePage.coreCompetences[1]")'>
    </MainArticleTitle>
    <ul>
      <li class="ico1">
        <p>{{$t("homePage.coreCompetences[2]")}}</p>
        <p>{{$t("homePage.coreCompetences[3]")}}</p>
      </li>
      <li class="ico2">
        <p>{{$t("homePage.coreCompetences[4]")}}</p>
        <p>{{$t("homePage.coreCompetences[5]")}}</p>
      </li>
      <li class="ico3">
        <p>{{$t("homePage.coreCompetences[6]")}}</p>
        <p>{{$t("homePage.coreCompetences[7]")}}</p>
      </li>
      <li class="ico4">
        <p>{{$t("homePage.coreCompetences[8]")}}</p>
        <p>{{$t("homePage.coreCompetences[9]")}}</p>
      </li>
      <li class="ico5">
        <p>{{$t("homePage.coreCompetences[10]")}}</p>
        <p>{{$t("homePage.coreCompetences[11]")}}</p>
      </li>
      <li class="ico6">
        <p>{{$t("homePage.coreCompetences[12]")}}</p>
        <p>{{$t("homePage.coreCompetences[13]")}}</p>
      </li>
    </ul>
  </section>
  <section id="quality-route">
    <MainArticleTitle :h2-title='t("homePage.qualityRoute[0]")' :h3-title='t("homePage.qualityRoute[1]")'>
    </MainArticleTitle>
    <div class="map">
      <img src="./assets/images/bg17.png"/>
    </div>
  </section>
  <section id="contact-us">
    <div class="left">
      <h2>{{$t("homePage.contactUs[0]")}}</h2>
      <h3>{{$t("homePage.contactUs[1]")}}</h3>
      <div class="core-business-learn-more">
        <button class="core-business-learn-more-button"><a>LEARN MORE</a></button>
      </div>
    </div>
    <div class="right">
      <dl>
        <dt>{{$t("homePage.contactUs[2]")}}</dt>
        <dt>{{$t("homePage.contactUs[3]")}}</dt>
        <dt>{{$t("homePage.contactUs[4]")}}</dt>
        <dt>{{$t("homePage.contactUs[5]")}}info@gblogistics.cn</dt>
        <dt>{{$t("homePage.contactUs[6]")}}</dt>
      </dl>
    </div>
  </section>
</template>

<style scoped>
@font-face {
  font-family: "SourceHanSansRegular";
  src: url('./assets/font/SourceHanSansCN-Regular.otf');
}
article p{
  width: 1020px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: small;
  font-family: SourceHanSansRegular, sans-serif;
}
.gallery{
  display: grid;
  /*align-items: stretch;*/
  width: 1020px;
  margin: 37px auto 0;
}
.gallery>div{
  background-size: cover; /* 拉伸适应父元素 */
  background-position: center; /* 图片居中 */
  padding: 70px;
}
.gallery div:first-child{
  background-image: url("./assets/images/bg4.png");
  grid-column: 1 / span 2;
  grid-row: 1;
}
.gallery div:nth-child(2){
  background-image: url("./assets/images/bj1.png");
  grid-column: 3;
  grid-row: 1 / span 2;
}
.gallery-HPM3{
  background-image: url("./assets/images/bg2.png");
}
.gallery-HPM4{
  background-image: url("./assets/images/bg3.png");
}
#about-us{
  margin-top: 80px;
}
.about-us-learn-more{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 35px;
}
.about-us-learn-more-button{
  padding: 10px;
  border: none;
  color: white;
  background-color: rgb(185,200,208);
}
#core-business{
  margin-top: 60px;
  background: url("./assets/images/bg5.png") center center no-repeat;
  padding: 55px 0 35px;
}
.core-business-gallery{
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
}
.core-business-gallery>figure{
  margin: 0 15px;
}
.core-business-gallery>figure>figcaption{
  color: white;
  text-align: center;
  padding-top: 35px;
  width: 230px;
  font-weight: 600;
}

.core-business-learn-more{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 40px;
}
.core-business-learn-more-button{
  padding: 10px;
  border: 1px solid rgba(240,240,240,0.8);
  color: white;
  background-color: rgba(255,255,255,0);
  cursor:pointer;
}
#core-business>article>.biz-h2,.biz-h3{
  color: white;
  text-align: center;
  font-size: large;
}
#core-business article .line{
  width: 20px;
  border-bottom: 5px solid rgb(0,212,254);
  padding-left: 20px;
  padding-right: 20px;
  margin: 30px auto 2vw;
}
#core-competences{
  background:url("./assets/images/bg10.png") center center no-repeat;
  padding-top: 50px;
  padding-bottom: 50px;
}
#core-competences>ul{
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 1441px;
  justify-content: center;
}
#core-competences>ul>li{
  width: 300px;
  padding-left: 100px;
  margin-left: 85px;
  margin-bottom: 10px;
}
#core-competences>ul>li.ico1{
  background: url("./assets/images/bg11.png") left center no-repeat;
}
#core-competences>ul>li.ico2{
  background: url("./assets/images/bg12.png") left center no-repeat;
}
#core-competences>ul>li.ico3{
  background: url("./assets/images/bg13.png") left center no-repeat;
}
#core-competences>ul>li.ico4{
  background: url("./assets/images/bg14.png") left center no-repeat;
}
#core-competences>ul>li.ico5{
  background: url("./assets/images/bg15.png") left center no-repeat;
}
#core-competences>ul>li.ico6{
  background: url("./assets/images/bg16.png") left center no-repeat;
}
#core-competences>ul>li>p:first-child{
  font-size: small;
}
#core-competences>ul>li>p:nth-child(2){
  color: rgb(130,130,130);
  font-size: small;
}
.map{
  text-align: center;
}
#contact-us{
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
}
#contact-us>div{
  width: 40%;
}
#contact-us>.left{
  padding-left: 200px;
  background:url("./assets/images/bg18.png") center center no-repeat;
  width: 520px;
  height: 340px;
}
#contact-us>.left h2,h3{
  color: white;
  text-align:left;
  font-size: xx-large;
  margin: 0;
}
#contact-us>.left h2{
  padding-top: 60px;
  padding-bottom: 20px;
}
#contact-us>.left h3{
  margin-top: 0;
  padding-bottom: 60px;
}
#contact-us>.left>div{
  margin: 0;
  text-align:inherit;
}

#contact-us>.right{
  padding-left: 120px;
  background-color: rgba(162, 177, 193, 0.31);
  width: 600px;
  padding-top: 30px;
}
#contact-us>.right>dl>dt{
  padding-left: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: small;
}
#contact-us>.right>dl>dt:first-child{
  background: url(./assets/images/bg19.png) left center no-repeat;
}
#contact-us>.right>dl>dt:nth-child(2){
  background: url(./assets/images/bg20.png) left center no-repeat;
}
#contact-us>.right>dl>dt:nth-child(3){
  background: url(./assets/images/bg21.png) left center no-repeat;
}
#contact-us>.right>dl>dt:nth-child(4){
  background: url(./assets/images/bg22.png) left center no-repeat;
}
#contact-us>.right>dl>dt:nth-child(5){
  background: url(./assets/images/bg23.png) left center no-repeat;
}

#quality-route{
  margin-top: 80px;
}

</style>
